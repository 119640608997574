import React from 'react';
import styles from './styles.module.scss';

const Feature = ({feature, index, currentIndex = null}) => (
    <div
        className={[
        styles.feature,
        currentIndex !== null && currentIndex === index ? styles.activeFeature : null
        ].join(' ')}
    >
        <h2 className={styles.title}>
            { feature.title }
        </h2>
        <p className={styles.description}>
            { feature.description }
        </p>
    </div>
);

export default Feature;
