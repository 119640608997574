import React from 'react';
import styles from './styles.module.scss';
import { graphql } from 'gatsby';
import { usePageContext } from '../../context/page';

const About = () => {
  const { data } = usePageContext();
  const { firstSection, secondSection } = data.allAboutYaml.nodes[0];
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <div className={styles.textWrapper}>
              <h2 className={styles.title}>{firstSection.title}</h2>
              <p className={styles.text} dangerouslySetInnerHTML={{__html: firstSection.text[0]}}></p>
              <p className={styles.text} dangerouslySetInnerHTML={{__html: firstSection.text[1]}}></p>
            </div>
          </div>
          <div className={styles.imagesContainer}>
            <picture className={[styles.backdrop_1, 'lozad'].join(' ')} data-alt="" aria-hidden="true" data-iesrc="/assets/image_1_backdrop.png">
              <source type="image/webp" srcSet="/assets/image_1_backdrop.webp" />
              <source type="image/png" srcSet="/assets/image_1_backdrop.png" />
            </picture>
            <picture className={[styles.image_1, 'lozad'].join(' ')} data-alt="two kids are playing draki hero, one of them is holding a phone" data-iesrc="/assets/image_1.png">
              <source type="image/webp" srcSet="/assets/image_1.webp" />
              <source type="image/png" srcSet="/assets/image_1.png" />
            </picture>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <div className={styles.textWrapper}>
              <h2 className={styles.title}>{secondSection.title}</h2>
              <p className={styles.text} dangerouslySetInnerHTML={{__html: secondSection.text[0]}}></p>
            </div>
          </div>
          <div className={styles.imagesContainer}>
            <picture className={[styles.backdrop_2, 'lozad'].join(' ')} data-alt="" aria-hidden="true" data-iesrc="/assets/image_2_backdrop.png">
              <source type="image/webp" srcSet="/assets/image_2_backdrop.webp" />
              <source type="image/png" srcSet="/assets/image_2_backdrop.png" />
            </picture>
            <picture className={[styles.image_2, 'lozad'].join(' ')} data-alt="one of the kids is celebrating while the other is holding the phone and smiling" data-iesrc="/assets/image_2.png">
              <source type="image/webp" srcSet="/assets/image_2.webp" />
              <source type="image/png" srcSet="/assets/image_2.png" />
            </picture>
          </div>
        </div>
      </div>
    </section>
);
};

export const query = graphql`
  fragment About on Query {
    allAboutYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        firstSection {
          title
          text
        }
        secondSection {
          title
          text
        }
      }
    }
  }
`;

export default About;
