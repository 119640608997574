import React from "react"
import styles from "./styles.module.scss"

const ProductHuntBtn = () => (
  <div className={styles.productHunt}>
    <a
      href="https://www.producthunt.com/posts/draki-hero-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-draki-hero-2"
      target="_blank"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=160007&theme=light"
        alt="Draki Hero - A cool AR game for kids | Product Hunt Embed"
        style={{ width: "250px", height: "54px" }}
      />
    </a>
  </div>
)

export default ProductHuntBtn
