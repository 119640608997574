import React, { useState, useRef } from "react"
import styles from "./styles.module.scss"
import addToMailChimp from "gatsby-plugin-mailchimp"
import { usePageContext } from "../../context/page"
import { graphql } from "gatsby"
import Iphonex from "../svgs/Iphonex"

const Suggestions = () => {
  const {
    data: { allSuggestionYaml },
  } = usePageContext()
  const {
    title,
    subtitle,
    send,
    sending,
    sent,
    errors,
  } = allSuggestionYaml.nodes[0]
  const submitButtonRef = useRef(null)
  const [error, setError] = useState(null)
  const [subscribed, setSubscribed] = useState(null)
  const errorClasses = [styles.error]

  if (error) {
    errorClasses.push(styles.showError)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const email = form.querySelector("input")
    if (form.checkValidity()) {
      if (subscribed === email.value) {
        setError(errors.subscribed)
        return
      }

      setError(null)

      submitButtonRef.current.disabled = true
      submitButtonRef.current.innerText = sending

      addToMailChimp(email.value)
        .then(({ msg, result }) => {
          if (result !== "success") {
            throw msg
          }
          submitButtonRef.current.innerText = sent
          submitButtonRef.current.disabled = false
          setSubscribed(email.value)
        })
        .catch(err => {
          submitButtonRef.current.innerText = send
          submitButtonRef.current.disabled = false

          if (err.includes("is already subscribed")) {
            setError(errors.subscribed)
          } else {
            setError(errors.error)
          }
        })
    } else {
      if (email.validity.valueMissing) {
        setError(errors.empty)
      } else if (!email.validity.valid) {
        setError(errors.email)
      }
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.screens}>
          <div className={styles.screensWrapper}>
            <div className={styles.screenOne}>
              <Iphonex className={styles.iphonex} width="100%" />
              <div className={styles.imgBox}>
                <picture
                  className="lozad"
                  data-alt="a draki hero game screen telling the user that has lost connection to internet"
                  data-iesrc="/assets/mocks/mock-1.png"
                >
                  <source
                    type="image/webp"
                    srcSet="/assets/mocks/mock-1.webp 205w, /assets/mocks/mock-1@2x.webp 410w"
                  />
                  <source
                    type="image/png"
                    srcSet="/assets/mocks/mock-1.png 205w, /assets/mocks/mock-1@2x.png 410w"
                  />
                </picture>
              </div>
            </div>
            <div className={styles.screenTwo}>
              <Iphonex className={styles.iphonex} width="100%" />
              <div className={styles.imgBox}>
                <picture
                  className="lozad"
                  data-alt="a draki hero game screen telling the user that has won the game"
                  data-iesrc="/assets/mocks/mock-2.png"
                >
                  <source
                    type="image/webp"
                    srcSet="/assets/mocks/mock-2.webp 205w, /assets/mocks/mock-2@2x.webp 410w"
                  />
                  <source
                    type="image/png"
                    srcSet="/assets/mocks/mock-2.png 205w, /assets/mocks/mock-2@2x.png 410w"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{subtitle}</p>
          <form
            id="form"
            onSubmit={handleSubmit}
            noValidate
            className={styles.form}
          >
            <div className={styles.formContainer}>
              <input
                className={styles.email}
                type="email"
                required
                placeholder="name@domain.co"
                aria-label="Email"
              />
              <button
                className={styles.send}
                type="submit"
                ref={submitButtonRef}
              >
                {send}
              </button>
            </div>
            <div className={errorClasses.join(" ")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="14"
                viewBox="0 0 13 14"
                className={styles.errorIcon}
              >
                <path
                  fill="none"
                  stroke="#FFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M26.6829023,44.1148109 C17.6627068,44.3492432 19.2956543,38.5574054 20.0059003,33 C20.0059003,33 18.6706002,33.655315 16,34.9659449 L20.0059003,33 L23.1656494,35.9073486"
                  transform="translate(-15 -32)"
                />
              </svg>
              {error}
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Suggestions on Query {
    allSuggestionYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        title
        subtitle
        send
        sending
        sent
        errors {
          subscribed
          error
          empty
          email
        }
      }
    }
  }
`

export default Suggestions
