import React from "react"

const Iphonex = ({ width, height, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1234 2488"
    preserveAspectRatio="xMidYMid"
    {...rest}
  >
    <defs>
      <path
        id="a"
        d="M225.421.771h780.022c128.504 0 228.391 51.283 228.391 224.53v2008.682c0 92.502-4.453 253.152-216.952 253.152H194.245c-92.672 0-193.41-55.867-193.41-214.353V225.301C.834 40.398 116.372.77 225.42.77zm626.115 162.777c-60.46 0-418.762-.636-472.221-.636-63.243-.428-68.733-57.882-68.733-68.06 0-10.176-3.183-19.08-18.457-19.08H184.571c-53.46 0-110.737 36.89-110.737 117.034v2097.73c0 63.607 29.912 121.489 125.362 121.489h819.082c93.553 0 135.556-44.525 135.556-123.396V199.803c0-97.953-63.641-123.396-119.001-123.396h-96.744c-16.724 0-21.002 4.877-21.002 19.718-.528 27.98-20.365 68.059-65.55 67.423z"
      />
    </defs>
    <use fill="#FFF" fillRule="evenodd" xlinkHref="#a" />
  </svg>
)

export default Iphonex
