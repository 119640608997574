import React, { useState, useEffect } from 'react';
import Feature from './feature';

const FeatureList = ({features, activeIndex = null}) => {

    const [currentIndex, setCurrentIndex] = useState(activeIndex);

    useEffect(() => {
      setCurrentIndex(activeIndex);
    }, [activeIndex]);

    return (
      <>
        {features.map((feature, index) => (
          <Feature
            key={index}
            feature={feature}
            index={index}
            currentIndex={currentIndex}>
          </Feature>
        ))}
      </>
    );
};

export default FeatureList;
