import React, { useEffect } from "react"
import lozad from "lozad"
import "../styles/global.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/Intro"
import About from "../components/About"
import Features from "../components/Features"
import Mentions from "../components/Mentions"
import Suggestions from "../components/Suggestions"
import Footer from "../components/Footer"
import ScrollHeader from "../components/ScrollHeader"
import "../styles/global.scss"
import { graphql } from "gatsby"
import ProductHuntBtn from "../components/ProductHuntBtn"
import { ParallaxProvider } from 'react-scroll-parallax';

const IndexPage = () => {
  useEffect(() => {
    const observer = lozad('.lozad', { rootMargin: '0px 0px 100px 0px'})
    observer.observe()
  }, [])

  return (
    <ParallaxProvider>
      <Layout>
        <SEO keywords={[`draki`, `hero`, `game`]} />
        <ScrollHeader />
        <Intro />
        <About />
        <Features />
        <Mentions />
        <Suggestions />
        <Footer />
        <ProductHuntBtn />
      </Layout>
    </ParallaxProvider>
  )
}

export const query = graphql`
  query($lang: String!) {
    allIndexYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        title
      }
    }
    allHeaderYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        share {
          twitter
          facebook
          text
        }
      }
    }
    allDownloadYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        downloadFrom
        store
        claim
      }
    }
    allFile(filter: { extension: { eq: "zip" } }) {
      nodes {
        name
        publicURL
        relativePath
      }
    }
    ...Intro
    ...About
    ...Features
    ...Mentions
    ...Suggestions
    ...Footer
  }
`

export default IndexPage
