import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper/lib/ReactIdSwiper.full"
import "react-id-swiper/src/styles/scss/swiper.scss"
import styles from "./styles.module.scss"
import Iphonex from "../svgs/Iphonex"
import { Pagination } from "swiper/dist/js/swiper.esm"
import { breakpoints } from "../utils"
import featuresData from "./featuresData"
import FeatureList from "./featureList"
import useWindowWidth from "../../hooks/useWindowWidth"
import { usePageContext } from "../../context/page"
import { graphql } from "gatsby"

const featuresImages = featuresData.map((feature, index) => (
  <div className={styles.featureImageContainer} key={index}>
    <picture
      className={[styles.featureImage, "lozad"].join(" ")}
      aria-hidden="true"
      data-iesrc={feature.img + ".png"}
      data-alt={feature.title}
    >
      {/* <source srcSet={feature.img + ".webp"} type="image/webp" /> */}
      <source srcSet={feature.img + ".png"} type="image/png" />
    </picture>
  </div>
))

const featuresBackgroundImages = featuresData.map((feature, index) => {
  const fileName = index + 1
  const sizes = [1440, 1240, 1024, 768, 640, 320]

  const srcset = ext => {
    return sizes
      .map(size => {
        return `/backgrounds/features/responsive/${fileName}-${size}.${ext} ${size}w`
      })
      .join(", ")
  }

  return (
    <div className={styles.featureImageContainer} key={index}>
      <picture
        className={[styles.featureBackgroundImage, "lozad", "ftBg"].join(" ")}
        data-alt=""
        aria-hidden="true"
        data-iesrc={`/backgrounds/features/responsive/${fileName}-320.jpg`}
      >
        <source srcSet={srcset("webp")} type="image/webp" />
        <source srcSet={srcset("jpg")} type="image/jpg" />
      </picture>
    </div>
  )
})

const params = {
  modules: [Pagination],
  pagination: {
    el: ".swipe-bullets",
    type: "bullets",
    clickable: true,
  },
  spaceBetween: 30,
}

const Features = () => {
  const { data } = usePageContext()
  const featuresIntl = data.allFeaturesYaml.nodes[0].features
  const [swiperQuotes, setSwiperQuotes] = useState(null)
  const [swiperImages, setSwiperImages] = useState(null)
  const [swiperBackgroundImages, setSwiperBackgroundImages] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (swiperQuotes && swiperImages) {
      window.swiper = swiperImages
      swiperImages.on("slideChange", function() {
        setSlideIndex(this.realIndex)
        swiperBackgroundImages.slideTo(this.realIndex)
      })

      if (windowWidth >= breakpoints.tablet) {
        // If the swiper image wasn't looping then
        // we are coming from a resize or loaded on
        // desktop.
        if (!swiperImages.params.loop) {
          swiperImages.params.loop = true
          swiperImages.init()
          swiperImages.loopCreate()
        }

        // The quotes swiper was removed on resize
        swiperImages.controller.control = false

        if (windowWidth >= breakpoints.desktop) {
          swiperImages.params.autoplay = {
            delay: 3000,
          }
          swiperImages.autoplay.start()
        }
      }

      if (windowWidth < breakpoints.tablet) {
        // If autoplay is enabled we are resizing from desktop
        if (swiperImages.autoplay) {
          swiperImages.autoplay.stop()
          swiperImages.params.autoplay = false
          swiperImages.params.loop = false
          swiperImages.loopDestroy()
          swiperImages.update()
        }

        swiperImages.controller.control = swiperQuotes

        if (swiperQuotes.hasOwnProperty("controller")) {
          swiperQuotes.controller.control = swiperImages
        }
      }
    }
  }, [swiperQuotes, swiperImages, swiperBackgroundImages, windowWidth])

  return (
    <section className={styles.section}>
      <div className={styles.backgroundSlider}>
        <Swiper
          containerClass={styles.featuresImagesBackgroundContainer}
          getSwiper={setSwiperBackgroundImages}
          effect="fade"
        >
          {featuresBackgroundImages}
        </Swiper>
      </div>
      <div className={styles.container}>
        <div className={styles.iphoneContainer}>
          <Iphonex className={styles.iphonex} height="100%" />
          <div className={styles.iphoneSwiperContainer}>
            <Swiper
              containerClass={styles.featuresImagesContainer}
              wrapperClass={styles.featuresImagesWrapper}
              getSwiper={setSwiperImages}
              grabCursor={true}
              loop={() => windowWidth >= breakpoints.tablet}
            >
              {featuresImages}
            </Swiper>
          </div>
        </div>
        {windowWidth >= breakpoints.tablet ? (
          <>
            <div className={styles.featuresContainer}>
              <FeatureList features={featuresIntl} activeIndex={slideIndex} />
            </div>
            {windowWidth < breakpoints.desktop ? (
              <div className={styles.swipeToDiscover}>
                <svg xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#FFF"
                    stroke="#FFF"
                    strokeWidth=".5"
                    d="M19.6695967 18.96v-5.88c-.0038375-.7970685-.4317418-1.5304105-1.1200925-1.9196133-.6883507-.38920283-1.5306825-.37406951-2.2049075.0396133-.358749-.77798288-1.1298119-1.27665772-1.9791666-1.28-.4047149-.00384663-.8023949.10701297-1.1479167.32-.358749-.77798288-1.129812-1.27665772-1.9791667-1.28-.3264182.00458223-.6489272.07247888-.95.2V3.2c0-1.21502645-.9747134-2.2-2.1770833-2.2s-2.1770833.98497355-2.1770833 2.2v10l-.6729167-1.4c-.3310815-.85944249-1.1457341-1.42936948-2.0583333-1.44-.2553051.00073498-.5089745.04120965-.7520834.12-.5524298.19274969-1.003601.6041289-1.2499314 1.1396912C.954585 12.1552535.9342564 12.7689915 1.1445967 13.32c0 .0220914.0177221.04.0395834.04l4.5520833 9.76c0 .04.0395833.04.0395833.08.4699602.7217852 1.043605 1.3688733 1.7020834 1.92.0395833.04.0791666.04.11875.08l.0395833.04.0791667.08c2.2330297 1.6738332 5.1977199 1.9662025 7.7088624.760226 2.5111426-1.2059766 4.1590334-3.7135385 4.2848876-6.520226v-.52l-.0395834-.08zM8.4279301 24.32l-.0791667-.08c-.069395-.0668359-.1497576-.1209749-.2375-.16-.5065268-.4242125-.9468893-.9231496-1.30625-1.48l-4.5125-9.72c-.1572752-.4850852.0852353-1.0102207.5541667-1.2.4805175-.1676744 1.0060173.0801403 1.1875.56 0 .0220914.017722.04.0395833.04l1.8604167 4c.1322336.2663742.4222802.4129241.7125.36.2735063-.0698252.4676254-.3150283.475-.6V3.2c0-.34297498.1810689-.65989693.475-.83138442.293931-.17148749.656069-.17148749.95 0 .293931.17148749.475.48840944.475.83138442v8c0 .3534622.283553.64.6333333.64s.6333333-.2865378.6333333-.64c0-.53019336.4253295-.96.95-.96.5246705 0 .95.42980664.95.96v.96c0 .3534622.283553.64.6333334.64.3497803 0 .6333333-.2865378.6333333-.64 0-.5301934.4253295-.96.95-.96.5246705 0 .95.4298066.95.96v.96c0 .3534622.283553.64.6333333.64.3497804 0 .6333334-.2865378.6333334-.64 0-.5301934.4253295-.96.95-.96.5246705 0 .95.4298066.95.96v6.4c-.1727738 3.3851494-2.9394101 6.040241-6.29375 6.04-1.3589571-.0316316-2.6793848-.462508-3.8-1.24z"
                  />
                </svg>
                Swipe to discover
              </div>
            ) : null}
          </>
        ) : (
          <Swiper
            getSwiper={setSwiperQuotes}
            containerClass={styles.featuresSwiperContainer}
            wrapperClass={styles.featuresWrapper}
            grabCursor={true}
            {...params}
          >
            {featuresIntl.map((feature, index) => (
              <div className={styles.feature} key={index}>
                <h2 className={styles.title}>{feature.title}</h2>
                <p className={styles.description}>{feature.description}</p>
              </div>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment Features on Query {
    allFeaturesYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        features {
          img
          title
          description
        }
      }
    }
  }
`

export default Features
