import React from 'react';
import styles from './styles.module.scss';
import { graphql } from 'gatsby';
import { usePageContext } from '../../context/page';

const Mentions = () => {

    const { data: {allMentionsYaml} } = usePageContext();
    const { familiar, run, jamtoday } = allMentionsYaml.nodes[0];
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.mention}>
                    <p className={styles.text}>
                        “{familiar}”
                    </p>

                    <a href="https://itch.io/jam/familiargamejamx" target="#">
                        <img className={'lozad'} data-src="/logos/Familiar_games.svg" data-alt="Quote from Familiar Games" />
                    </a>
                </div>
                <div className={styles.mention}>
                    <p className={styles.text}>
                        “{run}”
                    </p>
                    <a href="https://tecnologeria.com/run" target="#">
                        <img className={'lozad'} data-src="/logos/Run_big.svg" alt="Quote from Run" />
                    </a>
                </div>
                <div className={styles.mention}>
                    <p className={styles.text}>
                        “{jamtoday}”
                    </p>
                    <a href="https://www.jamtodayandalucia.com/" target="#">
                        <img className={'lozad'} data-src="/logos/jam_Today.png" data-alt="Quote from Jam Today" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export const query = graphql`
    fragment Mentions on Query {
        allMentionsYaml(filter: {lang: {eq: $lang}}) {
            nodes {
                familiar
                run
                jamtoday
            }
        }
    }
`;

export default Mentions;
