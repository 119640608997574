import React from 'react';
import styles from './styles.module.scss';
import { graphql } from "gatsby"
import {usePageContext} from '../../context/page';

const Footer = () => {
    const {data: {allFooterYaml,allFile}} = usePageContext();
    const {about, lab, more, press, pressUrl} = allFooterYaml.nodes[0];
    const urlPressKit = allFile.nodes.filter( file => file.relativePath.includes(pressUrl))[0].publicURL;
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <p className={styles.text}>
                    {about} <a rel="noopener noreferrer nofollow" target="_blank" href="https://z1.digital" className={styles.link}>{lab}</a>.
                    <br></br>
                    {more} <a rel="noopener noreferrer nofollow" target="_blank" className={styles.link} href="http://bit.ly/DrakiHeroZ1">Medium</a>.
                </p>
                <div className={styles.links}>
                    <a rel="noopener noreferrer nofollow" target="_blank" href={urlPressKit} download="Draki-PressKit" className={styles.link}>
                        <svg className={styles.linkIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                            <g fill="none" stroke="#FFF" strokeWidth="2" transform="translate(1 .788)">
                                <path d="M8.91176471,13.2987013 L4.42156863,13.2987013 L4.42156863,13.5358852 C4.42156863,14.9108484 5.44185921,16 6.66666667,16 C7.89147412,16 8.91176471,14.9108484 8.91176471,13.5358852 L8.91176471,13.2987013 Z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.8782959.392822266L17.8258936 1.51798873M20 5.30432129L21.7421827 4.77895127M20 10.0463867L21.7924709 10.4601694M15.1400697 12.316774L2.30242191 12.316774C1.11121951 12.316774.145559168 11.3573842.145559168 10.1739169.145559168 9.40750855.557549077 8.69945126 1.22603867 8.31697486L12.4864526 1.8121322"/>
                                <path d="M14.0621351,12.4484516 C15.3611347,12.4504324 16.4188638,10.0235968 16.4142421,7.00998214 C16.4096203,3.99636753 15.3528263,1.55174447 14.0538266,1.5497637 C12.754827,1.54778294 11.7055264,3.98919455 11.7101481,7.00280916 C11.7147699,10.0164238 12.7631354,12.4464708 14.0621351,12.4484516 Z" transform="rotate(-14 14.062 7)"/>
                            </g>
                        </svg>
                        {press}
                    </a>
                </div>
            </div>
        </section>
    );
};

export const query = graphql`
  fragment Footer on Query {
    allFooterYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        about
        lab
        more
        press
        pressUrl
      }
    }
    allFile(
      filter: {
        extension: { eq: "zip" }
      }
    ) {
      nodes {
       name
       publicURL
       relativePath
      }
    }
  }
`

export default Footer;
