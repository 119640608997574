export default [
    {
      img: '/backgrounds/features/content/1',
      title: 'Have fun together',
      description: 'Team play for children between five and ten years old that encourages communication and problem solving.'
    },
    {
      img: '/backgrounds/features/content/2',
      title: 'A step into future',
      description: 'Help children understand Augmented Reality, one of the most promising technologies of the 21st century.'
    },
    {
      img: '/backgrounds/features/content/3',
      title: 'Easy game mechanics',
      description: 'Avoid frustration thanks to graphics and sound indicators that guide the experience; it´s challenging while simple to understand.'
    },
    {
      img: '/backgrounds/features/content/4',
      title: 'Discover new scenarios',
      description: 'Play wherever you want: at home or while enjoying the fresh air, the device works with any indoor or outdoor space.'
    }
  ];
