import React from 'react';
import styles from './styles.module.scss';
import DownloadButton from '../DownloadButton';
import { usePageContext } from '../../context/page';

const Download = (props) => {
    const { data } = usePageContext();

    return (
        <div className={styles.download}>
            <DownloadButton {...props}></DownloadButton>
            <h3 className={styles.downloadText}>{data.allDownloadYaml.nodes[0].claim}</h3>
        </div>
    );
};

export default Download;
