import React from 'react';
import styles from './styles.module.scss';

const ShareButton = ({children, link = null}) => (
    <a target="_blank" rel="noopener noreferrer" href={link} className={styles.link}>
        {children}
    </a>
);

export default ShareButton;
