import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { random } from '../../utils';
import { breakpoints } from '../../utils';
import useWindowWidth from '../../../hooks/useWindowWidth';

const Detail = ({type, position = {}, dimensions = null}) => {

    const [randomStyles, setRandomStyles] = useState(null);

    const size = (dimensions = null) => {
        const randomSize = dimensions ? dimensions : random(6, 16);
        return {
            width: randomSize + 'px',
            height: randomSize + 'px',
        }
    }

    useEffect(() => {
        const TYPES = {
            red: '1',
            yellow: '2',
            green: '3',
            white: '4'
        };
        const background = type => `/assets/fold_details/${TYPES[type]}.svg`;
        setRandomStyles({
            backgroundImage: `url('${background(type)}')`,
            ...size(dimensions),
            ...position
        });
    }, [dimensions, type, position])


    return (
        <div className={styles.detail} style={randomStyles}></div>
    );
}

const Details = () => {
    const windowWidth = useWindowWidth();

    return (
        <div className={styles.details}>
            {windowWidth >= breakpoints.tablet ?
                <>
                    <Detail type="green" position={{top: '25%', left: '6%'}}></Detail>
                    <Detail type="white" position={{top: '60%', left: '5%'}}></Detail>
                    <Detail type="red" position={{top: '85%', right: '16%'}}></Detail>
                    <Detail type="red" position={{top: '27%', left: '30%'}}></Detail>
                    <Detail type="green" position={{top: '66%', left: '30%'}}></Detail>
                    <Detail type="yellow" position={{bottom: '14%', left: '32%'}}></Detail>
                    <Detail type="yellow" position={{top: '10%', left: '46%'}}></Detail>
                    <Detail type="white" position={{top: '43%', left: '54%'}}></Detail>
                    <Detail type="green" position={{top: '72%', left: '50%'}}></Detail>
                    <Detail type="green" position={{top: '9%', right: '46%'}}></Detail>
                    <Detail type="red" position={{top: '47%', right: '38%'}}></Detail>
                    <Detail type="green" position={{top: '3%', right: '5%'}}></Detail>
                    <Detail type="white" position={{top: '23%', right: '3%'}}></Detail>
                    <Detail type="red" position={{top: '36%', right: '9%'}}></Detail>
                    <Detail type="yellow" position={{top: '70%', right: '6%'}}></Detail>
                </>
                :
                <>
                    <Detail type="red" position={{top: '72%', left: '5%'}}></Detail>
                    <Detail type="red" position={{top: '60%', right: '5%'}}></Detail>
                    <Detail type="red" position={{top: '60%', right: '5%'}}></Detail>
                    <Detail type="yellow" position={{top: '21%', left: '90%'}}></Detail>
                    <Detail type="yellow" position={{top: '53%', left: '8%'}}></Detail>
                    <Detail type="green" position={{top: '14%', left: '6%'}}></Detail>
                    <Detail type="green" position={{top: '74%', left: '88%'}}></Detail>
                    <Detail type="white" position={{top: '44%', left: '89%'}}></Detail>
                    <Detail type="white" position={{top: '38%', left: '12%'}}></Detail>
                </>
            }
        </div>
    );
};

export default Details;
