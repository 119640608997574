import React, { useEffect, useCallback, useRef } from "react"
import { Link } from "gatsby"
import DownloadButton from "../DownloadButton"
import styles from "./styles.module.scss"
import { breakpoints } from "../utils"
const ScrollHeader = () => {
  const headerRef = useRef(null);

  const isDesktop = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth > breakpoints.tablet
    }
  }

  useEffect(() => {
    const header = document.getElementById("header")
    const scrollHeader = headerRef.current;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          scrollHeader.className = `${styles.header}`;
        } else {
          scrollHeader.className = `${styles.header} ${styles.show}`;
        }
      })
    }, { rootMargin: '-150px 0px 0px 0px'});

    observer.observe(header);
    return () => observer.unobserve(header);
  }, [])

  return (
    <header className={styles.header} ref={headerRef}>
      <div className={styles.container}>
        <Link to="/" className={styles.link}>
          <img
            className={styles.logo}
            src={"/logos/logo_navbar.svg"}
            alt="Logo"
          />
        </Link>
        <div className={styles.download}>
          <DownloadButton small />
        </div>
      </div>
    </header>
  )
}

export default ScrollHeader
