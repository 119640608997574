import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const useWindowWidth = () => {
    const [width, setWidth] = useState(null);
    const updateWidth = () => setWidth(window.innerWidth);

    useEffect(() => {
        updateWidth();
        const debouncedHanlder = debounce(updateWidth, 1000);
        window.addEventListener('resize', debouncedHanlder);

        return () => {
            window.removeEventListener('resize', debouncedHanlder);
        }
    }, []);

    return width;
};

export default useWindowWidth;
