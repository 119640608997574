import React, { useState, useEffect, useRef } from "react"
import styles from "./styles.module.scss"
import Download from "../Download"
import Details from "./Details"
import Header from "../Header"
import { graphql } from "gatsby"
import { usePageContext } from "../../context/page"
import { breakpoints } from "../utils"
import useWindowWidth from "../../hooks/useWindowWidth"
import { Parallax } from 'react-scroll-parallax';

const Intro = () => {
  const { data } = usePageContext()
  const [belowFold, setBelowFold] = useState(false);
  const containerRef = useRef(null);
  const windowWidth = useWindowWidth()

  useEffect(() => {
    const container = containerRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
          setBelowFold(!entry.isIntersecting);
      });
    });
    observer.observe(container);
    return () => observer.unobserve(container);
  }, [])

  return (
    <section className={styles.section} id="header">
      { windowWidth >= breakpoints.desktop ?
        <Parallax disabled={belowFold} styleInner={{width: '100%', height: '100%'}} styleOuter={{position: 'absolute', width: '100%', height: '100%'}} y={[-25, 25]}>
          <div className={styles.drops} />
        </Parallax>
        :
        <div className={styles.drops} />
      }
      <Header />
      <div ref={containerRef} className={styles.container}>
          <h1 className={styles.title}>
            {data.allIntroYaml.nodes[0].title[0]} <wbr />
            {data.allIntroYaml.nodes[0].title[1]}
          </h1>
          <p className={styles.subtitle}>
            {data.allIntroYaml.nodes[0].subtitle}
          </p>
          <div className={styles.download} id="introDownloadButton">
            <Download />
          </div>
          <video className={styles.video} controls autoPlay muted loop poster="/video/poster.webp" playsInline>
            <source type="video/webm" src={`/video/${data.allIntroYaml.nodes[0].video}.webm`}></source>
            <source type="video/mp4" src={`/video/${data.allIntroYaml.nodes[0].video}.mp4`}></source>
            <track label="English" kind="subtitles" srcLang="en" src="/video/intro-english-subtitles.vtt" />
            <track label="Español" kind="subtitles" srcLang="es" src="/video/intro-spanish-subtitles.vtt" />
          </video>
      </div>
      <Details />
    </section>
  )
}

export const query = graphql`
  fragment Intro on Query {
    allIntroYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        title
        subtitle
        video
      }
    }
  }
`

export default Intro
